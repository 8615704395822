import React, { FunctionComponent } from "react";
import { bem } from "../../classnames";
import { Container, ResponsiveContentSwitch } from "../../components";

import { DesktopProfile } from "./DesktopProfile";
import { MobileProfile } from "./MobileProfile";
import "./Profile.scss";

const cx = bem("profile");

export const Profile: FunctionComponent = () => (
    <Container>
        <div className={cx()}>
            <ResponsiveContentSwitch
                desktop={<DesktopProfile />}
                mobile={<MobileProfile />}
            />
        </div>
    </Container>
);
