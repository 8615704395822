import "react-app-polyfill/ie9";

import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";
import { Router } from "./Router";
import "./index.scss";

ReactDOM.render(
    <React.StrictMode>
        <Router />
    </React.StrictMode>,
    document.getElementById("root")
);

serviceWorker.unregister();
