import React, { FunctionComponent, ReactNode, useState } from "react";
import { Link } from "react-router-dom";

import { bem } from "../../classnames";
import { strings, getLanguageKey, CZECH_KEY } from "../../translations";
import { LanguageDesktopMenuItem } from "../LanguageDesktopMenuItem";
import { MobileMenuItem } from "../MenuItem";
import { MobileLogo } from "../Logo";
import "./Menu.scss";

const cx = bem("mobile-menu")

export type MobileMenuProps = {
    leftContent: ReactNode;
}

export const MobileMenu: FunctionComponent<MobileMenuProps> = ({ leftContent, children }) => {
    const [menuExpanded, setMenuExpanded] = useState(false);
    const closeMenu = () => setMenuExpanded(false)
    const selectedLanguageKey = getLanguageKey();

    return (
        <div className={cx()}>
            <div className={cx("header")}>
                <div className={cx("left-content")}>
                    {menuExpanded ? <MobileLogo /> : <Link to="/" >{leftContent}</Link>}
                </div>
                <div className={cx("button")}>
                    <input
                        type="checkbox"
                        className={cx("toggler")}
                        onClick={() => setMenuExpanded(!menuExpanded)}
                    />
                    <div className={cx("hamburger")}><div /></div>
                </div>
            </div>
            {menuExpanded ? (
                <ul className={cx("content")}>
                    <li className={cx("item")}>
                        <MobileMenuItem text={strings["profile"]} link="/profil" onClick={closeMenu} />
                    </li>
                    <li className={cx("item")}>
                        <MobileMenuItem text={strings["services"]} link="/sluzby" onClick={closeMenu} />
                    </li>
                    {selectedLanguageKey == CZECH_KEY ? (
                        <li className={cx("item")}>
                            <MobileMenuItem text={strings["map"]} link="/zdravybuxus" onClick={closeMenu} />
                        </li>
                    ) : null}
                    <li className={cx("item")}>
                        <MobileMenuItem text={strings["priceList"]} link="/cenik" onClick={closeMenu} />
                    </li>
                    <li className={cx("item")}>
                        <MobileMenuItem text={strings["contact"]} link="/kontakt" onClick={closeMenu} />
                    </li>
                    <li className={cx("item")}>
                        <LanguageDesktopMenuItem />
                    </li>
                </ul>
            ) : (
                <div className={cx("children")}>
                    {children}
                </div>
            )}
        </div>
    );
};
