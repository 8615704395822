import React, { FunctionComponent, useState } from "react";
import { bem } from "../../classnames";
import { Container, Footer, Header, MobileMenu, ResponsiveContentSwitch } from "../../components";
import { strings } from "../../translations";
import "./Map.scss";

const cxDesktop = bem("desktop-map");
const cxMobile = bem("mobile-map");

export const Map: FunctionComponent = () => {
    const [isLegendVisible, setIsLegendVisible] = useState(false)

    return (
        <ResponsiveContentSwitch
            desktop={
                <div className={cxDesktop()}>
                    <div className={cxDesktop("container")}>
                        <Header text={strings["map"]} />
                        <div className={cxDesktop("content_wrapper")}>
                            <div className={cxDesktop("map_wrapper")}>
                                <embed
                                    className={cxDesktop("map")}
                                    type="text/html"
                                    src="/map"
                                />
                                {isLegendVisible ?
                                    <div
                                        className={cxDesktop("legend")}
                                        onClick={() => setIsLegendVisible(false)}
                                    />
                                    :
                                    <div
                                        className={cxDesktop("legend_button")}
                                        onClick={() => setIsLegendVisible(true)}
                                    >
                                        Legenda
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            }
            mobile={
                <Container>
                    <div className={cxMobile()}>
                        <MobileMenu leftContent={strings["map"]}>
                            <div className={cxMobile("container")}>
                                <img
                                    src="/images/map.jpeg"
                                    className={cxMobile("map")}
                                />
                            </div>
                        </MobileMenu>
                    </div>
                </Container>
            }
        />
    )
};
