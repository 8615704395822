import React, { FunctionComponent } from "react";

import { getLanguageKey } from "../../translations";
import { bem } from "../../classnames";
import { ResponsiveContentSwitch, MobileMenu, DesktopMenu, MobileLogo, Container } from "../../components";
import { strings } from "../../translations";
import "./Homepage.scss";

const cxDesktop = bem("desktop-homepage");
const cxMobile = bem("mobile-homepage");

export const Homepage: FunctionComponent = () => (
    <Container>
        <ResponsiveContentSwitch
            mobile={
                <div className={cxMobile()}>
                    <MobileMenu leftContent={
                        <MobileLogo />
                    }>
                        <div className={cxMobile("content")}>
                            <div className={cxMobile("logo")} />
                            <div className={cxMobile("title", { language: getLanguageKey() })}>
                                {strings["phytopathologist"]}
                            </div>
                            <div className={cxMobile("name")}>
                                {strings["nameText"]}
                            </div>
                        </div>
                    </MobileMenu>
                </div>
            }
            desktop={
                <div className={cxDesktop()}>
                    <DesktopMenu />
                    <div className={cxDesktop("content")}>
                        <div className={cxDesktop("logo")} />
                        <div className={cxDesktop("title", { language: getLanguageKey() })}>
                            {strings["phytopathologist"]}
                        </div>
                        <div className={cxDesktop("name")}>
                            {strings["nameText"]}
                        </div>
                    </div>
                </div>
            }
        />
    </Container>
);
