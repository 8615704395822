import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Homepage, Profile, PriceList, Contact, Services, Map } from "./screens";

const router = createBrowserRouter([
    { path: "/", element: <Homepage /> },
    { path: "/profil", element: <Profile /> },
    { path: "/sluzby", element: <Services /> },
    { path: "/cenik", element: <PriceList /> },
    { path: "/kontakt", element: <Contact /> },
    { path: "/zdravybuxus", element: <Map /> },
    { path: "*", element: <Homepage /> },
]);

export const Router = () => (
    <RouterProvider router={router} />
);
