import React, { FunctionComponent } from "react";

import { Footer } from "../Footer";
import { Page } from "../Page";
import "./Container.scss";

export const Container: FunctionComponent = ({children}) => (
    <div className="container">
        <Page>
            {children}
        </Page>
        <Footer />
    </div>
);
