import React from "react";

import { Translation } from "./types";
import { general } from "./general";
import { NON_BREAKING_SPACE } from "./constants";

export const english: Translation = {
    profile: "Profile",
    services: "Services",
    priceList: `Price${NON_BREAKING_SPACE}list`,
    contact: "Contact",
    phytopathologist: "Phytopathologist",
    nameText: "Marie Machanderova",
    footerText: "© Marie Machanderova; This website doesn’t use cookies.",
    authorText: "Pavel Pospíšil",
    titledName: `Ing.${NON_BREAKING_SPACE}Marie Machanderova,${NON_BREAKING_SPACE}Ph.D.`,
    ico: undefined,
    dic: undefined,
    country: "Czech Republic",
    references: "Reference",
    referencesList: [
        {
            title: "ABIES zahrada, park, zeleň (Chateau Dobříš)",
            description: `control of${NON_BREAKING_SPACE}the health conditions and${NON_BREAKING_SPACE}vitality of${NON_BREAKING_SPACE}boxwood plantings and${NON_BREAKING_SPACE}consultation in${NON_BREAKING_SPACE}plant protection`,
        },
        {
            title: "Gardenline s.r.o.",
            description: `phytosanitary supervision of${NON_BREAKING_SPACE}the revitalization of${NON_BREAKING_SPACE}chateau park in${NON_BREAKING_SPACE}Slatiňany`,
        },
        {
            title: "GARPEN ZAHRADNICKA spol. s.r.o.",
            description: `health control of${NON_BREAKING_SPACE}newly planted full-grown trees`,
        },
        {
            title: "GHMP (Chateau Troja)",
            description: `regular monitoring of${NON_BREAKING_SPACE}the health condition and${NON_BREAKING_SPACE}vitality of${NON_BREAKING_SPACE}ornamental and${NON_BREAKING_SPACE}fruit woody plants and${NON_BREAKING_SPACE}consultations in${NON_BREAKING_SPACE}plant protection`,
        },
        {
            title: "NPÚ (Flower Garden Kroměříž)",
            description: `regular monitoring of${NON_BREAKING_SPACE}the health condition and${NON_BREAKING_SPACE}vitality of${NON_BREAKING_SPACE}boxwood plants and${NON_BREAKING_SPACE}consultations in${NON_BREAKING_SPACE}plant protection`,
        },
        {
            title: "NPÚ (Chateau Libochovice)",
            description: `control of${NON_BREAKING_SPACE}the health condition of${NON_BREAKING_SPACE}boxwood plants and${NON_BREAKING_SPACE}consultation in${NON_BREAKING_SPACE}plant protection`,
        },
        {
            title: "MONTANO spol. s.r.o.",
            description: `regular control of${NON_BREAKING_SPACE}the health condition of${NON_BREAKING_SPACE}container plants`,
        },
        {
            title: "PASIČ VLASTIMIL",
            description: `regular control of${NON_BREAKING_SPACE}the health condition of${NON_BREAKING_SPACE}plants in${NON_BREAKING_SPACE}greenhouse`,
        },
    ],
    profileMainText: `Marie earned her doctorate in${NON_BREAKING_SPACE}plant medicine from Mendel University in${NON_BREAKING_SPACE}Brno. She expanded the${NON_BREAKING_SPACE}knowledge from her studies with theoretical and${NON_BREAKING_SPACE}practical experience during internships in${NON_BREAKING_SPACE}research institutes and${NON_BREAKING_SPACE}laboratories in${NON_BREAKING_SPACE}Germany, the${NON_BREAKING_SPACE}USA, Chile as${NON_BREAKING_SPACE}well as${NON_BREAKING_SPACE}in${NON_BREAKING_SPACE}Czech plant production nurseries.`,
    profileSecondaryText: (
        <span>The PhD. dissertation itself was focused on&nbsp;pathogens of&nbsp;the genus <i>Buxus</i> and&nbsp;its protection.</span>
    ),
    serviceList: [
        {
            title: `Diagnostic of${NON_BREAKING_SPACE}plant samples`,
            description: `sampling of${NON_BREAKING_SPACE}symptomatic plants, identification of${NON_BREAKING_SPACE}pathogens and${NON_BREAKING_SPACE}pests of${NON_BREAKING_SPACE}ornamental plants using laboratory methods`,
        },
        {
            title: `Advising of${NON_BREAKING_SPACE}protective measures`,
            description: `recommendations for the use of${NON_BREAKING_SPACE}appropriate biological control or${NON_BREAKING_SPACE}pesticides, their combinations and${NON_BREAKING_SPACE}dosage`,
        },
        {
            title: "Soil",
            description: `sampling and${NON_BREAKING_SPACE}interpretation of${NON_BREAKING_SPACE}soil and${NON_BREAKING_SPACE}substrate tests results, advising in${NON_BREAKING_SPACE}plant fertilization`,
        },
        {
            title: "Water",
            description: `sampling and${NON_BREAKING_SPACE}interpretation of${NON_BREAKING_SPACE}water tests results`,
        },
    ],
    priceLineList: [
        { title: `Control at${NON_BREAKING_SPACE}the site and${NON_BREAKING_SPACE}report calculated based on${NON_BREAKING_SPACE}the spent time.`, priceItemList: [] },
        { title: "", priceItemList: [] },
        {
            title: "Sample analysis", priceItemList: [
                { price: "10", priceUnit: "€/Piece" },
            ]
        },
        {
            title: `Isolation of${NON_BREAKING_SPACE}fungi pathogen`, priceItemList: [
                { price: "35", priceUnit: "€/Piece" },
            ]
        },
        {
            title: <span><i>Phytophthora</i>&nbsp;&nbsp;test</span>, priceItemList: [
                { price: "20", priceUnit: "€/Piece" },
            ]
        },
        { title: "", priceItemList: [] },
        { title: `Soil and${NON_BREAKING_SPACE}water analysis based on${NON_BREAKING_SPACE}the extend.`, priceItemList: [] },
    ],
    address: general.address,
    phoneNumber: general.phoneNumber,
    mail: general.mail,
    mobilePriceList: [
        { title: `Control at${NON_BREAKING_SPACE}the site and${NON_BREAKING_SPACE}report calculated based on${NON_BREAKING_SPACE}the spent time.` },
        { title: "Sample analysis", priceText: "12 €" },
        { title: `Isolation of${NON_BREAKING_SPACE}fungi pathogen`, priceText: "35 €" },
        { title: <span><i>Phytophthora</i>&nbsp;&nbsp;test</span>, priceText: "20 €" },
        { title: `Soil and${NON_BREAKING_SPACE}water analysis based on${NON_BREAKING_SPACE}the extend.` },
    ],
    map: "Map",
};
